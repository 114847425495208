import React from "react"
import { isBefore } from "date-fns"
import useLazyFetch from "../hooks/useLazyFetch"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import * as theme from "theme"
import WaveAltIcon from "../icons/flipped-wave-sm.svg"
import VerticalLine from "../images/Line-events.png"

import {
  Typography,
  IconButton,
  Grid,
  Select,
  FormControl,
  MenuItem
} from "@material-ui/core"

import { BackgroundImage, Button, Edges, Spacer } from "components"

const EventsPage = (props) => {
  const {
    data: {
      wp: {
        themeOptions: {
          siteOptions: { news }
        }
      }
    }
  } = props
  const [getEvents, { data, error, loading, called }] = useLazyFetch(
    `${process.env.GATSBY_WP}/graphql`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: /* GraphQL */ `
          query EventsQuery {
            events(first: 300) {
              nodes {
                title
                acfEvent {
                  dealersName
                  endDate
                  eventLocation
                  fieldGroupName
                  startDate
                }
              }
            }
          }
        `
      })
    }
  )

  !called && getEvents()

  const allEvents = data?.data?.events?.nodes
  const orderedActiveEvents =
    allEvents &&
    allEvents.sort(
      (a, b) => new Date(a.acfEvent.startDate) - new Date(b.acfEvent.startDate)
    )
  const heroImage = news.postBackgroundImage

  return (
    <Container>
      <PageIntroContainer>
        <PageIntroOverlay />
        <PageIntroHero>
          <Edges size="ml">
            <Grid container>
              <Grid item xs={12} md={10}>
                <Typography variant="h1" children={"Events"} />
              </Grid>
            </Grid>
          </Edges>
        </PageIntroHero>
        {heroImage && (
          <HeroImageContainer>
            <BackgroundImage image={heroImage} />
          </HeroImageContainer>
        )}
      </PageIntroContainer>
      <HeroIntroText>
        <Edges size="ml">
          <WaveContainer>
            <WaveAltIcon />
          </WaveContainer>
        </Edges>
      </HeroIntroText>
      <OuterContainer>
        {orderedActiveEvents &&
          orderedActiveEvents?.map((event, index) => {
            const EventStart = new Date(event?.acfEvent?.startDate)
            const EventFinish = new Date(event?.acfEvent?.endDate)

            return (
              <PostContainer key={index}>
                <Edges size="ml">
                  <Box>
                    <SvgCont>
                      <img src={VerticalLine} />
                    </SvgCont>
                    <DateBox>
                      <Typography
                        variant="h6"
                        children={`${EventStart.toLocaleString("default", {
                          month: "short"
                        }).toUpperCase()} | ${EventStart.getFullYear()}`}
                      />
                      <Typography
                        variant="h1"
                        children={`${("0" + EventStart.getDate()).slice(-2)}-${(
                          "0" + EventFinish.getDate()
                        ).slice(-2)} `}
                      />
                    </DateBox>
                    <TextBox>
                      {event?.title && (
                        <Typography variant="h5" children={event?.title} />
                      )}
                      {event?.acfEvent?.eventLocation && (
                        <Typography
                          className="address"
                          variant="p"
                          children={event?.acfEvent?.eventLocation}
                        />
                      )}
                      {event?.acfEvent?.dealersName && (
                        <Typography
                          variant="p"
                          children={`Walker Bay Dealer: ${event?.acfEvent?.dealersName}`}
                        />
                      )}
                    </TextBox>
                  </Box>
                </Edges>
              </PostContainer>
            )
          })}
      </OuterContainer>
    </Container>
  )
}

export default EventsPage

const pageIntroBaseHeight = 295

const Container = styled.div`
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
`
const PageIntroContainer = styled.div`
  height: ${pageIntroBaseHeight - 45}px;
  padding: ${({ border }) => (border ? "20px" : 0)};
  position: relative;
  z-index: 4;

  @media (min-width: 600px) {
    height: ${pageIntroBaseHeight}px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${pageIntroBaseHeight}px;
    background: radial-gradient(
      33.94% 50% at 77.5% 120.75%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      28.09deg,
      #000000 14.29%,
      rgba(0, 0, 0, 0) 39.6%
    );
  }
`

const PageIntroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  position: absolute;
  width: 100%;
  height: 110px;
  bottom: 0px;
  left: 0px;
  z-index: 1;

  @media (min-width: 768px) {
    height: ${pageIntroBaseHeight - 30}px;
  }
`

const PageIntroHero = styled.div`
  align-items: center;
  background: linear-gradient(28.09deg, #000000 14.29%, rgba(0, 0, 0, 0) 39.6%);
  backdrop-filter: blur(12px);
  bottom: 0;
  color: white;
  display: flex;
  height: ${pageIntroBaseHeight - 60}px;
  left: 0;
  padding: 65px 0px 40px;
  position: absolute;
  width: 100%;
  z-index: 1;

  h1 {
    margin-bottom: 12px;
  }

  @media (min-width: 600px) {
    padding: 95px 0px 40px;
    height: ${pageIntroBaseHeight - 30}px;
  }
`

const HeroImageContainer = styled.div`
  position: relative;
  height: ${pageIntroBaseHeight - 60}px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: ${pageIntroBaseHeight}px;
  }
`

const HeroIntroText = styled.div`
  background: radial-gradient(
    17.98% 717.28% at 14.84% -12.35%,
    #000000 41.89%,
    #252525 100%
  );
  color: white;
  padding: 0px;
  position: relative;
  width: 100%;
  z-index: 9;

  @media (min-width: 768px) {
    z-index: 3;
  }
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: -5px;

  svg {
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    top: 0px;
  }
`
const OuterContainer = styled.div`
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
  position: relative;
  z-index: 1;
  padding: 50px 0px;
  @media (min-width: 600px) {
    padding: 100px 0px;
  }
`
const smoothBounce = keyframes`
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
`

const PostContainer = styled.div`
  padding: 0px 0px;
`
const Box = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  position: relative;
  padding: 10% 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  @media (min-width: 600px) {
    flex-direction: row;
    gap: 5%;
    padding: 3% 0px;
  }
`

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  gap: 2%;
  margin-bottom: 5%;
  @media (min-width: 600px) {
    margin-bottom: 0px;
  }
`
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  .address {
    margin-top: 6%;
  }

  span {
    font-weight: 300 !important;
    font-size: 16px;
  }
`
const SvgCont = styled.div`
  display: none;

  @media (min-width: 600px) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
`
export const CollectionQuery = graphql`
  query EventsTemplate {
    wp {
      themeOptions {
        siteOptions {
          news {
            headerTitle
            postBackgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`
